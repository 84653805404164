<template>
  <AppTableControl
    :search-place-holder="$t('COMMON.Actions.Search')"
    @query-debounced="searchName"
    no-export
    no-filter
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";

export default {
  name: "CompanyLeaveAssignmentTableControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeAssignedEmployeeListForLeaveBaseFilter", {
      base: null,
    });
  },
  methods: {
    /**
     * Search name.
     *
     * @param nameQuery
     */
    searchName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeAssignedEmployeeListForLeaveBaseFilter", {
        base: nameQuery,
      });

      this.$store.dispatch("fetchAssignedEmployeeListForLeave");
    },
  },
};
</script>

<style scoped></style>
