<template>
  <div>
    <CompanyLeaveAssignmentTableControl
      class="mb-5"
    ></CompanyLeaveAssignmentTableControl>

    <AppPageCard>
      <template #card-title> {{ $t("LEAVE.ASSIGNMENT_TITLE") }}</template>

      <template #card-toolbar>
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>
      </template>

      <CompanyLeaveAssignmentPageTable
        :reload="reload"
      ></CompanyLeaveAssignmentPageTable>
    </AppPageCard>
  </div>
</template>

<script>
import CompanyLeaveAssignmentPageTable from "@/modules/company/components/leave/assignment/CompanyLeaveAssignmentPageTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import CompanyLeaveAssignmentTableControl from "@/modules/company/components/leave/assignment/control/CompanyLeaveAssignmentTableControl";

export default {
  name: "CompanyLeaveAssignmentPage",
  components: {
    CompanyLeaveAssignmentTableControl,
    AppPageCard,
    CompanyLeaveAssignmentPageTable,
  },
  data() {
    return {
      reload: false,
    };
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
